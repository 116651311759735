import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";

const AccountBlockedPage = (props) => {

    useEffect(() => {
        navigate("/account-blocked");
    }, []);

    return (
        <Layout location={props.location} showBookNowButton={false}>
            <SEO
                title="Account Deactivated"
                keyword="Account Deactivated"
                decription="Account Deactivated"
            />
        </Layout>
    );
};

export default AccountBlockedPage;