import React, { useEffect } from "react";

import Layout from "../layouts/zh";
import SEO from "../components/seo";
import CompleteProfile from "../components/TWComponents/CompleteProfile";
import completeProfileLangData from "../data/static-pages/pages/complete-profile/zh";
import { useAuth0 } from "@auth0/auth0-react";
import { getGuestCompleteInfo } from "../apis/guest";
import { navigate } from "gatsby";

const CompleteProfilePage = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const handleCallback = async () => {
      const token = await getAccessTokenSilently();
      const guestInfo = await getGuestCompleteInfo(token);
      if (Object.keys(guestInfo).length > 0) {
        if (!guestInfo?.data?.data?.guest?.isEmailVerify) {
          return navigate(
            `/zh/verify-account?email=${guestInfo?.data?.data?.guest?.email}`,
            {
              replace: true,
            }
          );
        }
        if (
          guestInfo?.data?.data?.guest?.firstName &&
          guestInfo?.data?.data?.guest?.lastName
        ) {
          return navigate(`/zh/member`, {
            replace: true,
          });
        }
      }
    };
    handleCallback();
  }, []);
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Complete Profile "
        keyword="Complete Profile"
        decription="Complete Profile"
      />
      <CompleteProfile langData={completeProfileLangData} />
    </Layout>
  );
};

export default CompleteProfilePage;
