import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { setUserData } from "../../data/setUserData";
import Layout from "../../layouts/en";
import SEO from "components/seo";

const MemberPage = ({ ...props }) => {
    const [openWidget, setOpenWidget] = useState(false);
    const userData = setUserData();
    const location = useLocation();

    useEffect(() => {
        navigate("/member");
    }, []);


    return (
        <Layout
            location={location}
            showBookNowButton={false}
            mobileWidgetStatus={openWidget}
            setMobileWidgetStatus={setOpenWidget}>
            <SEO
                title={"Welcome " + userData?.firstname}
                keyword="Welcome"
                decription="Welcome"
            />
        </Layout>
    );
};

export default MemberPage;