import React, { useEffect } from "react";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import { navigate } from "gatsby";

const CompleteProfilePage = (props) => {

    useEffect(() => {
        navigate("/complete-profile");
    }, []);

    return (
        <Layout location={props.location} showBookNowButton={false}>
            <SEO
                title="Complete Profile "
                keyword="Complete Profile"
                decription="Complete Profile"
            />
        </Layout>
    );
};

export default CompleteProfilePage;