import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Header from "../partials/header";
import Footer from "../partials/footer";
import { getUrlForLang } from "ptz-i18n";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { IntlProvider } from "react-intl";
import "intl";
import { setExpiryStorage } from "../services/Auth";
import { AYANA_LANG_SESSION } from "state/constants/actions";
import { saveState } from "state/utils/localStorage";
import { Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryParam } from "../hooks/useQueryParam";
import { useDispatch, useSelector } from "react-redux";
import { fetchGuestInfoAction } from "../state/actions/guestInfoAction";
import posthog from 'posthog-js';

const getCurrentLangKey = (langs, defaultLangKey, url) => {
  const langKey = url.split("/");

  return (
    langs,
    defaultLangKey,
    langKey[1] === "ja" ? "ja" : langKey[1] === "zh" ? "zh" : "en"
  );
};

const Layout = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            languages {
              defaultLangKey
              langs {
                label
                slug
              }
            }
          }
        }
      }
    `
  );

  const dispatch = useDispatch();
  const [showLoading, setLoading] = useState(false);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const { code: codeFromSearchQuery } = useQueryParam(["code"]);
  const url = props.location.pathname + props.location.search;
  const { langs, defaultLangKey } = site.siteMetadata.languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/");

  const guestInformation = useSelector(
    (store) => store.guestInformation.member.data
  );

  const langsMenu = langs
    .map(function (obj) {
      return {
        langName: obj.slug,
        langKey: obj.label,
        selected: langKey === obj.label,
        link: getUrlForLang(homeLink, url, obj.label),
      };
    })
    .map((item) => ({
      ...item,
      link: item.link.replace(`/${defaultLangKey}/`, "/"),
    }));
  saveState(AYANA_LANG_SESSION, langKey);

  //Fetch guest info
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        try {
          const token = await getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
          });

          dispatch(fetchGuestInfoAction(token));
          saveState("auth_token", token);
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [isAuthenticated, dispatch, getAccessTokenSilently]);

  useEffect(() => {
    // 👉 Check the sign-in status and user info,
    //    and identify the user if they aren't already
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (user && !posthog._isIdentified()) {
      // 👉 Identify the user
      posthog.identify(user.sub, {
        email: user.email,
        username: user.name,
      });
    }

    // 👉 Reset the user if they sign out
    if (!user && posthog._isIdentified()) {
      posthog.reset();
    }
  }, [posthog, user, isAuthenticated]);

  return (
    <>
      <Spin spinning={showLoading}>
        <IntlProvider locale={langKey} messages={props.i18nMessages}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}>
            <Header
              lang_selected_name={props.i18nMessages.selectLanguage}
              home_link={homeLink}
              content={props.header_content}
              langkey={langKey}
              location={props.location}
              langs={langsMenu}
              setLoading={setLoading}
              showBookNowButton={props.showBookNowButton}
              mobileWidgetStatus={props.mobileWidgetStatus}
              setMobileWidgetStatus={props.setMobileWidgetStatus}
            />
            <div className="body header-spacing" style={{ flexGrow: 1 }}>
              <div className="page-wrapper">{props.children}</div>
            </div>
            <Footer
              home_link={homeLink}
              content={props.footer_content}
              location={props.location}
              footer_margin={props.footer_margin || showLoading}
            />
          </div>
        </IntlProvider>
      </Spin>
      {setExpiryStorage()}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
